<template>
  <common-loading
    :loading="!paramInfo.id || !paramInfo.hash || !succeedVerify ||
      loadingPatientActivateData"
  >
    <frame-auth
      title="Reset Password"
      width="400"
    >
      <form-activate
        :id="paramInfo.id"
        :hash="paramInfo.hash"
        :email.sync="activationData.email"
        :phone="activationData.phoneNumber"
      />
    </frame-auth>
  </common-loading>
</template>

<script>
import { useActivationPage } from '@/use/activate'

export default {
  name: 'ResetPassword',
  layout: 'auth',
  setup: (_, context) => {
    const {
      activationData,
      paramInfo,
      succeedVerify,
      loadingPatientActivateData
    } = useActivationPage({ context })

    return {
      activationData,
      paramInfo,
      succeedVerify,
      loadingPatientActivateData
    }
  }
}
</script>
